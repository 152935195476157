import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from './home';
import Rocket from './rocket';
// import NotFound from './error';
import './App.css';

function App() {
  return (
    <Router>
      <div>
        <Route exact path="/" component={Home} />
        <Route path="/rocket" component={Rocket} />
        {/* <Route path="*" component={NotFound} /> */}
      </div>
    </Router>
  );
}

export default App;
