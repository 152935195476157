import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <div className="rocket-landing__container min-h-screen h-full w-full">
      <div className="mx-auto rocket-landing__wrapper flex min-h-screen justify-between">
        <div className="w-1/2 flex px-20 bg-white items-center">
          <div>
            <h1 className="rocket-landing__title text-5xl leading-tight font-bold">Empowering Faculty to Launch Industry Elective at Universities</h1>
            <p className="mt-4">Just like a rocket launch, empowering faculty at academic institutions to launch industry courses requires careful planning.</p>
            <p className="mt-2">There are 10 critical stages where humans across Industry and Academia have to synchronise.</p>
            <p className="mt-2">If planning in any of the stages fail, the entire course launch at the University will fail.</p>
            <Link to="/rocket" className="inline-block mt-6 bg-purple-700 text-white border border-purple-800  px-6 py-3 shadow-lg rounded-lg hover:border-yellow-600 hover:bg-yellow-500 hover:shadow-xl focus:shadow-inner hover:text-gray-900 font-medium tracking-wide">View Infographics</Link>
          </div>
        </div>
        <div className="w-1/2 relative shadow-inner">
          <div className="rocket-landing__mini-rocket relative h-full">
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
