import React from 'react';
import { Link } from 'react-router-dom';
import level1 from './images/level-1.svg';
import level2 from './images/level-2.svg';
import level3 from './images/level-3.svg';
import level4 from './images/level-4.svg';
import level5 from './images/level-5.svg';
import level6 from './images/level-6.svg';
import level7 from './images/level-7.svg';
import level8 from './images/level-8.svg';
import level9 from './images/level-9.svg';
import level10 from './images/level-10.svg';
import universityBadge from './images/university-badge.png';
import industryBadge from './images/industry-badge.png';
import pupilfirstBadge from './images/pupilfirst-badge.png';
import rocketFlame from './images/rocket-flame.png';

function Rocket() {
  return (
    <div className="rocket-container relative h-full w-full overflow-x-hidden">
      <Link to="/" className="flex flex-col justify-center items-center bg-gray-100 p-4 shadow rounded-lg text-center text-gray-700 text-sm hover:text-gray-900 hover:shadow-lg focus:shadow-inner focus:bg-gray-500 absolute right-0 mt-10 mr-10 z-10">
        <svg className="w-6 fill-current object-contain" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M417,227.89c0-7.35,5.88-13.23,11.76-13.23A13.17,13.17,0,0,1,442,227.89v229.3c0,7.35-5.88,11.76-13.23,11.76H303.82c-7.35,0-13.23-5.88-13.23-11.76V324.9H220V457.19A11.59,11.59,0,0,1,208.28,469H81.87a11.59,11.59,0,0,1-11.76-11.76V227.89c0-7.35,5.88-13.23,11.76-13.23A13.17,13.17,0,0,1,95.1,227.89V444h100V313.14a13.17,13.17,0,0,1,13.23-13.23h95.54c5.88,0,11.76,5.88,11.76,13.23V444H417ZM36.3,230.83c-5.88,4.41-14.7,2.94-17.64-1.47-4.41-5.88-2.94-14.7,1.47-17.64l219-163.16a28.66,28.66,0,0,1,33.8,0L490.5,211.72c5.88,2.94,7.35,11.76,2.94,17.64a13.38,13.38,0,0,1-17.64,1.47L258.25,67.67h-4.41Z" /></svg>
        <span>Home</span>
      </Link>
      <header className="container mx-auto">
        <header className="pt-6 max-w-3xl mx-auto text-center">
          <h1 className="rocket-container__title text-5xl font-bold leading-tight">Integration of Industry Courses with Universities</h1>
          <div className="flex items-center justify-center max-w-sm mx-auto mt-3">
            <img src={pupilfirstBadge} className="w-20 object-contain" alt="Industry Badge"></img>
            <img src={universityBadge} className="w-20 object-contain mx-3" alt="Industry Badge"></img>
            <img src={industryBadge} className="w-20 object-contain" alt="Industry Badge"></img>
          </div>
          <p className="text-lg rocket-container__sub-title mt-2 leading-normal font-medium max-w-2xl mx-auto">There are 10 critical stages that are required for effective implementation of Industry Courses in Universities as shown below.</p>
        </header>
      </header>
      <div className="tower-crane__container w-24">
        <div className="tower-crane__operator-cab-back-container fixed">
          <div className="tower-crane__operator-cab-back"></div>
        </div>
        <div className="tower-crane__container-block absolute left-0 top-0 bottom-0 w-24"></div>
        <div className="tower-crane__operator-cab-front-container fixed">
          <div className="tower-crane__operator-cab-front"></div>
        </div>
      </div>
      <div className="rocket-body">
        <div className="rocket-body__top mx-auto"></div>
        <div className="rocket-body__level mx-auto z-10">
          <div className="rocket-body__level-content flex h-full items-start justify-between">
            <div className="rocket-body__level-content-left mt-5 p-4 rounded-lg relative">
              <div className="rocket-body__level-content-badge-pupilfirst absolute w-16">
                <img src={pupilfirstBadge} className="rocket-body__level-content-badge-image object-contain" alt="Industry Badge"></img>
              </div>
              <p className="text-sm leading-snug font-medium pl-3">Coach Onboarding, Training &amp; Management</p>
            </div>
            <div className="rocket-body__level-content-right right-0 p-4 rounded-lg">
              <div className="rocket-body__level-content-right-number text-3xl shadow-inner text-center font-black">1</div>
              <div className="rocket-body__level-content-badge absolute w-20">
                <img src={industryBadge} className="rocket-body__level-content-badge-image object-contain" alt="Industry Badge"></img>
              </div>
              <h2 className="text-3xl font-bold">Industry Partnership</h2>
              <h5 className="text-sm font-semibold">Curriculum Design</h5>
              <p className="text-sm leading-snug mt-px">Industry engineers design modern curriculum to academic credit framework.</p>
              <h5 className="text-sm font-semibold mt-3">Coach nomination</h5>
              <p className="text-sm leading-snug mt-px">Coaches who can train the academic faculty and support in course evaluations are nominated.</p>
            </div>
          </div>
          <div className="rocket-body__level-image-container mx-auto z-20 relative">
            <img src={level1} className="rocket-body__level-image rocket-body__level-image--1 object-contain mx-auto" alt="logo" />
          </div>
        </div>
        <div className="rocket-body__level mx-auto z-10">
          <div className="rocket-body__level-content flex h-full items-start justify-between">
            <div className="rocket-body__level-content-left mt-5 p-4 rounded-lg relative">
              <div className="rocket-body__level-content-badge-pupilfirst absolute w-16">
                <img src={pupilfirstBadge} className="rocket-body__level-content-badge-image object-contain" alt="Industry Badge"></img>
              </div>
              <p className="text-sm leading-snug font-medium pl-3">Evangelise and Gets University Partner to the Framework</p>
            </div>
            <div className="rocket-body__level-content-right right-0 p-4 rounded-lg">
              <div className="rocket-body__level-content-right-number text-3xl shadow-inner text-center font-black">2</div>
              <div className="rocket-body__level-content-badge absolute w-20">
                <img src={universityBadge} className="rocket-body__level-content-badge-image object-contain" alt="Industry Badge"></img>
              </div>
              <h2 className="text-3xl font-bold">University Partnership</h2>
              <h5 className="text-sm font-semibold">Academic Council Approves Course</h5>
              <p className="text-sm leading-snug mt-px">University Academic Council meets to approve the course credits, course objectives, course outcomes and course evaluation rubric.</p>
            </div>
          </div>
          <div className="rocket-body__level-image-container mx-auto z-20 relative">
            <img src={level2} className="rocket-body__level-image rocket-body__level-image--2 object-contain mx-auto" alt="logo" />
          </div>
        </div>
        <div className="rocket-body__level mx-auto z-10">
          <div className="rocket-body__level-content flex h-full items-start justify-between">
            <div className="rocket-body__level-content-left mt-5 p-4 rounded-lg relative">
              <div className="rocket-body__level-content-badge-pupilfirst absolute w-16">
                <img src={pupilfirstBadge} className="rocket-body__level-content-badge-image object-contain" alt="Industry Badge"></img>
              </div>
              <p className="text-sm leading-snug font-medium pl-3">Faculty Training on Digital Pedagogy and Course Knowledge</p>
            </div>
            <div className="rocket-body__level-content-right right-0 p-4 rounded-lg">
              <div className="rocket-body__level-content-right-number text-3xl shadow-inner text-center font-black">3</div>
              <div className="flex absolute rocket-body__level-content-badge">
                <div className="w-20">
                  <img src={universityBadge} className="rocket-body__level-content-badge-image object-contain" alt="Industry Badge"></img>
                </div>
                <div className="w-20 ml-4">
                  <img src={industryBadge} className="rocket-body__level-content-badge-image object-contain" alt="Industry Badge"></img>
                </div>
              </div>
              <h2 className="text-3xl font-bold">Faculty identification</h2>
              <h5 className="text-sm font-semibold">Faculty Identification</h5>
              <p className="text-sm leading-snug mt-px">Faculty who are interested to learn new technology and teach students along with the industry are identified.</p>
              <h5 className="text-sm font-semibold mt-3">Faculty Training on Industry Curriculum</h5>
              <p className="text-sm leading-snug mt-px">The selected faculty is continuously trained both on
                <span className="font-medium block"> a.) industry curriculum <span className="font-normal">and</span> b.) how to deliver the course using digital teaching-learning pedagogy.</span>
              </p>
            </div>
          </div>
          <div className="rocket-body__level-image-container mx-auto z-20 relative">
            <img src={level3} className="rocket-body__level-image rocket-body__level-image--3 object-contain mx-auto" alt="logo" />
          </div>
        </div>
        <div className="rocket-body__level mx-auto z-10">
          <div className="rocket-body__level-content flex h-full items-start justify-between">
            <div className="rocket-body__level-content-left mt-5 p-4 rounded-lg relative">
              <div className="rocket-body__level-content-badge-pupilfirst absolute w-16">
                <img src={pupilfirstBadge} className="rocket-body__level-content-badge-image object-contain" alt="Industry Badge"></img>
              </div>
              <p className="text-sm leading-snug font-medium pl-3">Best Practices</p>
            </div>
            <div className="rocket-body__level-content-right right-0 p-4 rounded-lg">
              <div className="rocket-body__level-content-right-number text-3xl shadow-inner text-center font-black">4</div>
              <div className="rocket-body__level-content-badge absolute w-20">
                <img src={universityBadge} className="rocket-body__level-content-badge-image object-contain" alt="Industry Badge"></img>
              </div>
              <h2 className="text-3xl font-bold">Setting up Labs</h2>
              <h5 className="text-sm font-semibold">Spaces and Dimension</h5>
              <p className="text-sm leading-snug mt-px">When the course requires a Lab, suitable space is identified by the University and equipments are dimensioned based on the desired equipment to student ratio.</p>
              <h5 className="text-sm font-semibold mt-3">Equipment Procurement</h5>
              <p className="text-sm leading-snug mt-px">The University is facilitated for procurement of the Lab Equipment.</p>
            </div>
          </div>
          <div className="rocket-body__level-image-container mx-auto z-20 relative">
            <img src={level4} className="rocket-body__level-image rocket-body__level-image--4 object-contain mx-auto" alt="logo" />
          </div>
        </div>
        <div className="rocket-body__level mx-auto z-10">
          <div className="rocket-body__level-content flex h-full items-start justify-between">
            <div className="rocket-body__level-content-left mt-5 p-4 rounded-lg relative">
              <div className="rocket-body__level-content-badge-pupilfirst absolute w-16">
                <img src={pupilfirstBadge} className="rocket-body__level-content-badge-image object-contain" alt="Industry Badge"></img>
              </div>
              <p className="text-sm leading-snug font-medium pl-3">Students Identification Based on University Processes.</p>
            </div>
            <div className="rocket-body__level-content-right right-0 p-4 rounded-lg">
              <div className="rocket-body__level-content-right-number text-3xl shadow-inner text-center font-black">5</div>
              <div className="rocket-body__level-content-badge absolute w-20">
                <img src={universityBadge} className="rocket-body__level-content-badge-image object-contain" alt="Industry Badge"></img>
              </div>
              <h2 className="text-3xl font-bold">Student Identification</h2>
              <h5 className="text-sm font-semibold">Student Awareness</h5>
              <p className="text-sm leading-snug mt-px">Students awareness workshops are conducted by an industry expert who explains the specific industry outlook, skills students require, course design and evaluation.</p>
              <h5 className="text-sm font-semibold mt-3">Student Selection</h5>
              <p className="text-sm leading-snug mt-px">From amongst the students who are now interested to take the course, student aptitude is further tested through a suitable selection process.</p>
            </div>
          </div>
          <div className="rocket-body__level-image-container mx-auto z-20 relative">
            <img src={level5} className="rocket-body__level-image rocket-body__level-image--5 object-contain mx-auto" alt="logo" />
          </div>
        </div>
        <div className="rocket-body__level mx-auto z-10">
          <div className="rocket-body__level-content flex h-full items-start justify-between">
            <div className="rocket-body__level-content-left mt-5 p-4 rounded-lg relative">
              <div className="rocket-body__level-content-badge-pupilfirst absolute w-16">
                <img src={pupilfirstBadge} className="rocket-body__level-content-badge-image object-contain" alt="Industry Badge"></img>
              </div>
              <p className="text-sm leading-snug font-medium pl-3">Course Launch and Management</p>
            </div>
            <div className="rocket-body__level-content-right right-0 p-4 rounded-lg">
              <div className="rocket-body__level-content-right-number text-3xl shadow-inner text-center font-black">6</div>
              <div className="rocket-body__level-content-badge absolute w-20">
                <img src={industryBadge} className="rocket-body__level-content-badge-image object-contain" alt="Industry Badge"></img>
              </div>
              <h2 className="text-3xl font-bold">Course Delivery</h2>
              <h5 className="text-sm font-semibold">Evaluation Rubric</h5>
              <p className="text-sm leading-snug mt-px">At the beginning of the course, the Evaluation Rubric is explained well to students on how they will be evaluated at the end of the course.</p>
              <h5 className="text-sm font-semibold mt-3">Diagnostic Assessment for Skill Mapping</h5>
              <p className="text-sm leading-snug mt-px">The skill level of students at the start of the course is mapped with a diagnostic assessment.</p>
            </div>
          </div>
          <div className="rocket-body__level-image-container mx-auto z-20 relative">
            <img src={level6} className="rocket-body__level-image rocket-body__level-image--6 object-contain mx-auto" alt="logo" />
          </div>
        </div>
        <div className="rocket-body__level mx-auto z-10">
          <div className="rocket-body__level-content flex h-full items-start justify-between">
            <div className="rocket-body__level-content-left mt-5 p-4 rounded-lg relative">
              <div className="rocket-body__level-content-badge-pupilfirst absolute w-16">
                <img src={pupilfirstBadge} className="rocket-body__level-content-badge-image object-contain" alt="Industry Badge"></img>
              </div>
              <p className="text-sm leading-snug font-medium pl-3">Course Engagement</p>
            </div>
            <div className="rocket-body__level-content-right right-0 p-4 rounded-lg">
              <div className="rocket-body__level-content-right-number text-3xl shadow-inner text-center font-black">7</div>
              <div className="rocket-body__level-content-badge absolute w-20">
                <img src={industryBadge} className="rocket-body__level-content-badge-image object-contain" alt="Industry Badge"></img>
              </div>
              <h2 className="text-3xl font-bold">Course Evaluation</h2>
              <h5 className="text-sm font-semibold">Formative Assessment</h5>
              <p className="text-sm leading-snug mt-px">The progress of students is regularly assessed using formative assessment techniques. Peer leaders are identified to help their classmates on course doubts/clarifications.</p>
              <h5 className="text-sm font-semibold mt-3">Summative Assessment</h5>
              <p className="text-sm leading-snug mt-px">At the end of the course, a summative assessment is carried out to find the overall knowledge and skills gained by students.</p>
            </div>
          </div>
          <div className="rocket-body__level-image-container mx-auto z-20 relative">
            <img src={level7} className="rocket-body__level-image rocket-body__level-image--7 object-contain mx-auto" alt="logo" />
          </div>
        </div>
        <div className="rocket-body__level mx-auto z-10">
          <div className="rocket-body__level-content flex h-full items-start justify-between">
            <div className="rocket-body__level-content-left mt-5 p-4 rounded-lg relative">
              <div className="rocket-body__level-content-badge-pupilfirst absolute w-16">
                <img src={pupilfirstBadge} className="rocket-body__level-content-badge-image object-contain" alt="Industry Badge"></img>
              </div>
              <p className="text-sm leading-snug font-medium pl-3">Teaching Assistant Selection, Training and Management</p>
            </div>
            <div className="rocket-body__level-content-right right-0 p-4 rounded-lg">
              <div className="rocket-body__level-content-right-number text-3xl shadow-inner text-center font-black">8</div>
              <h2 className="text-3xl font-bold">Teaching Assistants</h2>
              <p className="text-sm leading-snug mt-px">Top performers are eligible to apply for becoming teaching assistants.</p>
              <p className="text-sm leading-snug mt-2">Teaching assistants play a critical role in supporting faculty in course delivery and course evaluation and scaling the course to more students.</p>
            </div>
          </div>
          <div className="rocket-body__level-image-container mx-auto z-20 relative">
            <img src={level8} className="rocket-body__level-image rocket-body__level-image--8 object-contain mx-auto" alt="logo" />
          </div>
        </div>
        <div className="rocket-body__level mx-auto z-10">
          <div className="rocket-body__level-content flex h-full items-start justify-between">
            <div className="rocket-body__level-content-left mt-5 p-4 rounded-lg relative">
              <div className="rocket-body__level-content-badge-pupilfirst absolute w-16">
                <img src={pupilfirstBadge} className="rocket-body__level-content-badge-image object-contain" alt="Industry Badge"></img>
              </div>
              <p className="text-sm leading-snug font-medium pl-3">Graduation Pathway</p>
            </div>
            <div className="rocket-body__level-content-right right-0 p-4 rounded-lg">
              <div className="rocket-body__level-content-right-number text-3xl shadow-inner text-center font-black">9</div>
              <div className="rocket-body__level-content-badge absolute w-20">
                <img src={industryBadge} className="rocket-body__level-content-badge-image object-contain" alt="Industry Badge"></img>
              </div>
              <h2 className="text-3xl font-bold">Course Graduation</h2>
              <h5 className="text-sm font-semibold">Internship/Jobs</h5>
              <p className="text-sm leading-snug mt-px">Students who like to work in the industry are guided to internship and job interviews.</p>
              <h5 className="text-sm font-semibold mt-3">Incubator</h5>
              <p className="text-sm leading-snug mt-px">Students who are risk-takers and want to startup are guided to University Incubator.</p>
            </div>
          </div>
          <div className="rocket-body__level-image-container mx-auto z-20 relative">
            <img src={level9} className="rocket-body__level-image rocket-body__level-image--9 object-contain mx-auto" alt="logo" />
          </div>
        </div>
        <div className="rocket-body__level mx-auto z-10">
          <div className="rocket-body__level-content flex h-full items-start justify-between">
            <div className="rocket-body__level-content-left mt-5 p-4 rounded-lg relative">
              <div className="rocket-body__level-content-badge-pupilfirst absolute w-16">
                <img src={pupilfirstBadge} className="rocket-body__level-content-badge-image object-contain" alt="Industry Badge"></img>
              </div>
              <p className="text-sm leading-snug font-medium pl-3">All Stakeholders Engagement in Teaching Learning Process</p>
            </div>
            <div className="rocket-body__level-content-right right-0 p-4 rounded-lg">
              <div className="rocket-body__level-content-right-number text-3xl shadow-inner text-center font-black">10</div>
              <h2 className="text-3xl leading-tight font-bold">Feedback &amp; Improvement</h2>
              <p className="text-sm leading-snug mt-2">The overall stages are evaluated along with all stakeholders and suggestions are taken on how to improve the course.</p>
            </div>
          </div>
          <div className="rocket-body__level-image-container mx-auto z-20 relative">
            <img src={level10} className="rocket-body__level-image rocket-body__level-image--10 object-contain mx-auto" alt="logo" />
          </div>
        </div>
        <div className="rocket-body__bottom mx-auto"></div>
        <div className="rocket-fin__container w-full mx-auto flex relative justify-between">
          <div className="rocket-fin__block rocket-fin-block__left absolute bottom-0"></div>
          <div className="rocket-fin__block rocket-fin-block__right absolute bottom-0"></div>
        </div>
        <div className="rocket-flame__container mx-auto flex justify-around">
          <div className="rocket-flame__block relative">
            <div className="rocket-flame__block-img absolute">
              <img src={rocketFlame} className="" alt="logo" />
            </div>
            <div className="rocket-flame__animate relative">
              <div className="rocket-flame__flame-red rocket-flame__flame"></div>
              <div className="rocket-flame__flame-orange rocket-flame__flame"></div>
              <div className="rocket-flame__flame-yellow rocket-flame__flame"></div>
              <div className="rocket-flame__flame-white rocket-flame__flame"></div>
              <div className="rocket-flame__flame-blue rocket-flame__flame-circle"></div>
              <div className="rocket-flame__flame-rocket-flame__flame-white-mask rocket-flame__flame-circle"></div>
            </div>
          </div>
          <div className="rocket-flame__block rocket-flame__block--middle relative">
            <div className="rocket-flame__block-img absolute">
              <img src={rocketFlame} className="" alt="logo" />
            </div>
            <div className="rocket-flame__animate">
              <div className="rocket-flame__flame-red rocket-flame__flame"></div>
              <div className="rocket-flame__flame-orange rocket-flame__flame"></div>
              <div className="rocket-flame__flame-yellow rocket-flame__flame"></div>
              <div className="rocket-flame__flame-white rocket-flame__flame"></div>
              <div className="rocket-flame__flame-blue rocket-flame__flame-circle"></div>
              <div className="rocket-flame__flame-white-mask rocket-flame__flame-circle"></div>
            </div>
          </div>
          <div className="rocket-flame__block relative">
            <div className="rocket-flame__block-img absolute">
              <img src={rocketFlame} className="" alt="logo" />
            </div>
            <div className="rocket-flame__animate">
              <div className="rocket-flame__flame-red rocket-flame__flame"></div>
              <div className="rocket-flame__flame-orange rocket-flame__flame"></div>
              <div className="rocket-flame__flame-yellow rocket-flame__flame"></div>
              <div className="rocket-flame__flame-white rocket-flame__flame"></div>
              <div className="rocket-flame__flame-blue rocket-flame__flame-circle"></div>
              <div className="rocket-flame__flame-white-mask rocket-flame__flame-circle"></div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default Rocket;
